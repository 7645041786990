<template>

<Button class="layout-menu-button shadow-6 p-ripple" label="Toggle p-disabled"  v-styleclass="{ selector: '@next', toggleClass: 's-disabled' }">

    <i class="pi pi-chevron-left"></i>
    <span class="p-ink" style="height: 35px; width: 35px; top: 6px; left: 12px;"></span>

</Button>

    <div id="app-sidebar-7" class="shadow-2 sidebar hidden lg:block flex-1 h-screen overflow-y-scroll lg:fixed left-0 top-0 z-5 border-right-1 select-none animation-duration-300 animation-ease-in-out bg-white-alpha-70 border-white-alpha-60" style="min-width:245px;backdrop-filter: blur(15px);">
                
        <!-- Adding Navigation Menu Module from ../modules/ -->
        <MainNavigation />
    </div>

</template>

<script>
export default {
    data() {
        
    },
    methods: {},
    computed:{},
    mounted() {}
}
</script>
