<template>
    <div>
        <Toast />
         <Toolbar class="col-12 mb-4">
            <template #start>
                <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
            </template>
            <template #end>
                    <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
                    <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
            </template>
        </Toolbar>
        
        
        <DataTable  
        :value="konds" 
        sortMode="multiple"  
        removableSort  
        :rowHover="true"  
        editMode="cell" 
        @cell-edit-complete="onCellEditComplete" 
        class="editable-cells-table"  
        :reorderableColumns="true" 
        @columnReorder="onColReorder" 
        @rowReorder="onRowReorder" 
        responsiveLayout="scroll"  
        columnResizeMode="fit" 
        :paginator="true" 
        :rows="10"  
        dataKey="id" 
        v-model:filters="filters" 
        filterDisplay="menu"  
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
        :rowsPerPageOptions="[10,25,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        :globalFilterFields="[ 'kuid','koid','kotyp','kober','koname','wtyp','fw','nwgv','gbzn','stat','edate','ename' ]" 
        v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
        
            <template #header >
                <div class="grid">
                    <div class="col-12 flex justify-content-end flex-wrap ">
                        <div class="col-3 right-0" >
                            <span class="p-input-icon-left flex align-items-center justify-content-center">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value"  class="p-column-filter" placeholder="Suche" />
                            </span>
                             
                        </div>            


                    </div>
                        
                </div>
            </template>
            <template #empty>
                Keine Konditionen gefunden.
            </template>
            <template #loading>
                Lade Daten. Bitte warten.
            </template>
              
              
            <Column headerStyle="width: 3rem"  :rowReorder="true" :reorderableColumn="false" style="background:#ddd;" class="drag"/>
            
            <Column field="kunde" header="Kunde" sortable style="max-width:110px;cursor: pointer;">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                     {{ data.kunde }}
                     {{ data.adr }}
                </template>
            </Column>
            <Column field="kuid" header="ID" sortable style="max-width:110px;">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.kuid }}
                </template>
            </Column>

            <Column field="koname" header="Kondition" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.koname }}
                </template>
            </Column>

            <Column field="koid" header="Kondit. ID" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.koid }}
                </template>
            </Column>

            <Column field="kotypname" header="Konditionstyp" sortable style="min-width: 7rem;background:pink;cursor: pointer;" >
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div> 
                </template>
                <template #editor="{ data }"> 
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.kotypname }} 
                    <div>
                       
                            Wertetyp: {{ data.wtyp }} 
                            {{ data.kotypkober }} 
                            ID: {{ data.kotypkoberid }}
                            Kürzel: {{ data.kotypkoberabc }}
                             
                        </div>
                </template>
            </Column>

            
            <Column field="kober" header="Konditionsbereich" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data }">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.kober }}
                </template>
            </Column>


            


            <Column field="wtyp" header="Wertetyp" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>

                <template #body>

                    <Dropdown v-model="selectedItem" :options="item" optionLabel="name" :filter="true" placeholder="Auswahl" :showClear="true">
                        <template #value="slotProps">
                            <div class="p-dropdown-car-value" v-if="slotProps.value">
                                <span>{{slotProps.value.name}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div class="p-dropdown-car-option">
                                <span>{{slotProps.option.name}}</span>
                            </div>
                        </template>
                    </Dropdown>

                   
                </template>

            </Column>

            <Column field="fw" header="Früherer Wert" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
            </Column>

             <Column field="nw" header="Neuer Wert" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
            </Column>


            <Column field="gv" header="Gültig von" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                        
                    </div>    
                </template>
                <template #editor="{ data, field }">
                     <Calendar v-model="data[field]" dateFormat="dd.mm.yy" />
                </template>
            </Column>

            <Column field="gb" header="Gültig bis" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                     <Calendar v-model="data[field]" dateFormat="dd.mm.yy" />
                </template>
            </Column>

            <Column field="stat" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
                <template #body="{data}">
                    <span :class="'customer-badge status-' + data.stat">{{data.stat}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                        <template #value="slotProps">
                            <span :class="'customer-badge status-' + slotProps.value">{{slotProps.value}}</span>
                        </template>
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>

            <Column field="edate" header="Erstellungsdatum" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                 <template #editor="{ data }">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.edate }}
                </template>
            </Column>


             <Column field="ename" header="Erstellername" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data }">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.ename }}
                </template>
            </Column>



            <template #expansion="slotProps">
                <div :value="slotProps"> 
                    <div class="flex-1">
        <div class="flex flex-column flex-auto">
            
        </div>

    
    </div>    
                </div>
            </template>

            


            <!-- <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
            </Column> -->
            
        </DataTable>
    </div>

</template>

<script>
import ProductService from '../service/ProductService';

import CustomerListService from '../service/CustomerListService';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import axios from 'axios';
 
export default {

    name: 'TableListXAPI',

    data() {
        return {
            
            selectedItem: null,
            item: [
                {name: 'Zahl 12,34', id:0 },
                {name: '%-Wert', id:1 },
                {name: 'Text', id:2 }
            ],
            //items: [],
            // item:null,
            products: null,
            nodes: null,
            value:null,
            expandedRows: [] ,

            editingRows: [],
            columns: null,

            selectedColumns: null,
            
            filters: {
                'global': {value: '', matchMode: FilterMatchMode.CONTAINS},
                'kuid': {value: 'kuid', matchMode: FilterMatchMode.CONTAINS},
                'koid': {value: 'koid', matchMode: FilterMatchMode.CONTAINS},
                'kotyp': {value: 'kotyp', matchMode: FilterMatchMode.CONTAINS},
                'kober': {value: 'kober', matchMode: FilterMatchMode.CONTAINS},
                'koname': { value: 'koname', matchMode: FilterMatchMode.CONTAINS},
                'wtyp': { value: 'wtyp', matchMode: FilterMatchMode.CONTAINS},
                'fw': { value: 'fw', matchMode: FilterMatchMode.CONTAINS},
                'nw': { value: 'nw', matchMode: FilterMatchMode.CONTAINS},
                'gv': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'gb': { value: 'gb', matchMode: FilterMatchMode.CONTAINS},
                'zn': { value: 'zn', matchMode: FilterMatchMode.CONTAINS},
                'stat': { value: 'stat', matchMode: FilterMatchMode.CONTAINS},
                'edate': { value: 'edate', matchMode: FilterMatchMode.CONTAINS},
                'ename': { value: 'ename', matchMode: FilterMatchMode.CONTAINS},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
            },



            kond:null,
            konds:[],
            kunde: null,
            kuid:null,
            koid:null,
            kotyp:null,
            kober:null,
            koname:null,
            wtyps:[],
            
            wtyp:[],
            fw:null,
            nw:null,
            gv:null,
            gb:null, 
            zn:null,
            stat:null,
            edate:null,
            ename:null,
            
            kotype:null,
            kotypid:null,
            kotypname:null,
            kotypwtyp:null,
            kotypkober:null,
            kotypkoberid:null,
            kotypkobername:null,
            kotypkoberabc:null,

            loading1: true,
            loading2: true,

        }
    },
    productService: null,
    customerListService: null,
    created() {
        this.productService = new ProductService();
        this.customerListService = new CustomerListService();

        // this.columns = [
        //     {field: 'name', expander: true},
        //     {field: 'id'},
        //     {field: 'type'}
        // ];

        // this.columns = [
        //     {field: 'kuid', header: 'Kunden ID', key:'1',order:'1'},
        //     {field: 'koid', header: 'Konditions ID', key:'2',order:'2'},

        //     {field: 'kotyp', header: 'Konditionstyp', key:'3', order:'3'},

        //     {field: 'kober', header: 'Konditionsbereich', key:'4', order:'4'},
        //     {field: 'koname', header: 'Konditionsname', key:'5',order:'5'},

        //     {field: 'wtyp', header: 'Wertetyp', key:'6',order:'6'},
        //     {field: 'fw', header: 'Früherer Wert', key:'7',order:'7'},
        //     {field: 'nw', header: 'Neuer Wert', key:'8',order:'8'},
        //     {field: 'gv', header: 'Gültig von', key:'9',order:'9'},
        //     {field: 'gb', header: 'Gültig bis', key:'10',order:'10'},

        //     {field: 'zn', header: 'Zeilennr.', key:'11',order:'11'},
        //     {field: 'stat', header: 'Status', key:'12',order:'12'},

        //     {field: 'edate', header: 'Erstelldatum', key:'13',order:'13'},
        //     {field: 'ename', header: 'Ersteller', key:'14',order:'14'},
            
        // ];
        this.selectedColumns = this.columns;
        this.initFilters();

    },
    mounted() {
        this.getallEvents();
        this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    },
    methods: {
        async getallEvents(){

            try {
                const res = await axios.get(`./dummydata-k.json`);

                const konds = res.data.results;
                for (const kond of konds) {
                let kunde = kond.kunde
                let adr = kond.adr
                let kuid = kond.kuid
                let koid = kond.koid 
                let kotyp = kond.kotyp   
                    let kotypid = kond.kotypid
                    let kotypname = kond.kotypname
                    let kotypwtyp = kond.kotypwtyp
                    let kotypkober = kond.kotypkober
                    let kotypkoberid = kond.kotypkoberid
                    let kotypkobername = kond.kotypkobername
                    let kotypkoberabc = kond.kotypkoberabc


                let kober = kond.kober
                let koname = kond.koname
                let wtyp = kond.wtyp
                let fw = kond.fw
                let nw = kond.nw
                let gv = kond.gv
                let gb = kond.gb
                let zn = kond.zn
                let stat = kond.stat
                let edate = kond.edate
                let ename = kond.ename



                this.konds.push({ 
                    adr,
                    kunde, 
                    kuid,
                    koid,
                    kotyp,kotypid,kotypname,kotypwtyp,kotypkober,kotypkoberid,kotypkobername,kotypkoberabc,
                    kober,
                    koname,
                    wtyp,
                    fw,
                    nw,
                    gv,
                    gb,
                    zn,
                    stat,
                    edate,
                    ename
                    })
                
                }
                 console.log(konds);
        
            } catch (e) {
                console.error(e);
            }
           

        },
        

        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;

                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },

        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch(status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'lowstock':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },




        formatDate(value) {
            return value.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('le-de', {style: 'currency', currency: 'EUR'});
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kuid': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'koid': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kotyp': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kober': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'koname': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'wtyp': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'fw': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'nw': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'gv': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'gb': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'zn': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'stat': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'edate': { value: null, matchMode: FilterMatchMode.CONTAINS},


            }
        },

        onRowExpand(event) {
            this.$toast.add({severity: 'info', summary: 'Kundenakte geöffnet', detail: event.data.name, life: 3000});
        },
        onRowCollapse(event) {
            this.$toast.add({severity: 'success', summary: 'Kundenakte geschlossen', detail: event.data.name, life: 3000});
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
            this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        },
        collapseAll() {
            this.expandedRows = null;
            this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },

        onColReorder() {
            this.$toast.add({severity:'success', summary: 'Column Reordered', life: 3000});
        },
        onRowReorder(event) {
            this.konds = event.value;
            this.$toast.add({severity:'success', summary: 'Rows Reordered', life: 3000});
        }
    },
    computed: {

    }
}
</script>
