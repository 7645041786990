<template>

<div class="footer px-4 md:px-6 lg:px-8">
    <div class="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
       
        <div class="mt-3 sm:mt-0">
            <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700">
              
            </a>
            <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
               
            </a>
            <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700">
              
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'AppFooter',

}
</script>