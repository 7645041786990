<template>
    <div ref="document" id="pdf-export" >
        
        <DataTable  
        :value="konds" 
        sortMode="multiple"  
        removableSort  
        :rowHover="true"  
        editMode="cell" 
        @cell-edit-complete="onCellEditComplete" 
        class="editable-cells-table"  
        :reorderableColumns="true" 
        @columnReorder="onColReorder" 
        @rowReorder="onRowReorder" 
        responsiveLayout="scroll"  
        columnResizeMode="fit"  
        dataKey="id" 
        v-model:filters="filters" 
        filterDisplay="menu"  
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
        :rowsPerPageOptions="[10,25,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        :globalFilterFields="[ 'kuid','koid','kotyp','kober','koname','wtyp','fw','nwgv','gbzn','stat','edate','ename' ]" 
        v-model:expandedRows="expandedRows" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
        
            <template #header >
                <div class="grid">
                    <div class="col-12 flex flex-wrap ">
                        <div class="col-3 left-0">
                            <span class="p-input-icon-left flex align-items-center justify-content-center">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value"  class="p-column-filter" placeholder="Suche" />
                            </span>
                        </div>
                        <div class="col-6"></div>
                        <div class="col-3 right-0">
                            <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                             <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportToPDF"  />
                        </div>            
                        

                    </div>
                        
                </div>
            </template>
            <template #empty>
                Keine Konditionen gefunden.
            </template>
            <template #loading>
                Lade Daten. Bitte warten.
            </template>
              
            <Column :rowReorder="true" :reorderableColumn="false"  class="drag"/>
            
            <Column field="kunde" header="Kunde" sortable style="min-width: 10rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template  #body="{data}">
                     <span style="display:block;width:100%">{{ data.kunde }}</span>
                </template>
            </Column>

            <Column field="adr" header="Adresse" sortable style="min-width: 10rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template  #body="{data}">
                     <span style="display:block;width:100%"> {{ data.adr }}</span>
                </template>
            </Column>

            <Column field="kuid" header="ID" sortable style="max-width:65px;">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.kuid }}
                </template>
            </Column>

            <Column field="koid" header="Kondit. ID" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.koid }}
                </template>
            </Column>
            
            <Column field="koname" header="Kondition" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data}">
                    <!-- <InputText v-model="data[field]" autofocus /> -->
                    {{ data.koname }}
                </template>
            </Column>

            <Column field="kotypid" header="Konditionstyp ID" sortable style="min-width: 7rem;" >
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div> 
                </template>
                <template #body="{ data }"> 
                    <span style="display:block;width:100%">{{ data.kotypkoberid }}</span>
                </template>
            </Column>

            <Column field="kotypkober" header="Konditionsbereich" sortable style="min-width: 7rem;" >
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div> 
                </template>
                <template #body="{ data }"> 
                    <span style="display:block;width:100%">{{ data.kotypkober }}</span>
                </template>
            </Column>
            
            <Column field="kotypkoberabc" header="Kürzel" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #body="{ data }">
                    <span style="display:block;width:100%"> {{ data.kotypkoberabc }}</span>
                </template>
            </Column>


            


            <Column field="wtyp" header="Wertetyp" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>

                <template #body="{ data }">
                    <span style="display:block;width:100%"> {{ data.wtyp }}</span>
                </template>

            </Column>

            <Column field="fw" header="Früherer Wert" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
             
                <template #body="{ data }">
                    <span style="display:block;width:100%"> {{ data.fw }}</span>
                </template>

            </Column>

             <Column field="nw" header="Neuer Wert" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
            </Column>


            <Column field="gv" header="Gültig von" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                        
                    </div>    
                </template>
                <template #editor="{ data, field }">
                     <Calendar v-model="data[field]" dateFormat="dd.mm.yy" />
                </template>
            </Column>

            <Column field="gb" header="Gültig bis" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data, field }">
                     <Calendar v-model="data[field]" dateFormat="dd.mm.yy" />
                </template>
            </Column>

            <Column field="stat" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
                <template #body="{data}">
                    <span :class="'customer-badge status-' + data.stat">{{data.stat}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                        <template #value="slotProps">
                            <span :class="'customer-badge status-' + slotProps.value">{{slotProps.value}}</span>
                        </template>
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>

            <Column field="edate" header="Erstellungsdatum" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                 <template #editor="{ data }">
                    {{ data.edate }}
                </template>
            </Column>


             <Column field="ename" header="Erstellername" sortable style="min-width: 4rem">
                <template #filter="{filterModel,filterCallback}">
                    <div class="p-column-filter">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder="..." v-tooltip.top.focus="'Eingabe drücken'"/>
                    </div>    
                </template>
                <template #editor="{ data }">
                    {{ data.ename }}
                </template>
            </Column>
            <Column :exportable="false" style="min-width:8rem">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
                </template>
            </Column>
        </DataTable>



        <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Konditionsdetails" :modal="true" class="p-fluid">
            <div class="formgrid grid">
                <div class="field col">
                    <label for="konds.kunde">Kunde</label>
                    <InputText id="konds.kunde" v-model.trim="konds.kunde" required="true" autofocus :class="{'p-invalid': submitted && !konds.kunde}" />
                    <small class="p-error" v-if="submitted && !konds.kunde">Name is required.</small>
                </div>
                <div class="field col">
                    <label for="adr">PLZ, Ort, Land</label>
                    <InputText id="adr" v-model.trim="konds.adr" required="true" autofocus :class="{'p-invalid': submitted && !konds.adr}" />
                    <small class="p-error" v-if="submitted && !konds.adr">PLZ, Ort, Land is required.</small>           
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="kuid">Kunden-ID</label>
                    <InputText id="kuid" v-model.trim="konds.kuid" required="true" autofocus :class="{'p-invalid': submitted && !konds.kuid}" />
                    <small class="p-error" v-if="submitted && !konds.kuid">Name is required.</small>           
                </div>
                <div class="field col">
                    <label for="koid">Konditions-ID</label>
                    <InputText id="koid" v-model.trim="konds.koid" required="true" autofocus :class="{'p-invalid': submitted && !konds.koid}" />
                    <small class="p-error" v-if="submitted && !konds.koid">Name is required.</small>          
                </div>
            </div>
            <div class="field">
                <label for="koname">Konditionsname</label>
                    <InputText id="koname" v-model.trim="konds.koname" required="true" autofocus :class="{'p-invalid': submitted && !konds.koname}" />
                    <small class="p-error" v-if="submitted && !konds.koname">Name is required.</small>
            </div>

            <div class="field">
                <label for="kotypname">Konditionstyp</label>
                    <InputText id="kotypname" v-model.trim="konds.kotypname" required="true" autofocus :class="{'p-invalid': submitted && !konds.kotypname}" />
                    <small class="p-error" v-if="submitted && !konds.kotypname">Name is required.</small>
            </div>
             <div class="field">
                <label for="kotypkober">Konditionsbereich</label>
                    <InputText id="kotypkober" v-model.trim="konds.kotypkober" required="true" autofocus :class="{'p-invalid': submitted && !konds.kotypkober}" />
                    <small class="p-error" v-if="submitted && !konds.kotypkober">Name is required.</small>
            </div>

             <div class="formgrid grid">
                <div class="field col">
                    <label for="wtyp">Wertetyp</label>
                    <InputText id="wtyp" v-model.trim="konds.wtyp" required="true" autofocus :class="{'p-invalid': submitted && !konds.wtyp}" />
                    <small class="p-error" v-if="submitted && !konds.wtyp">Name is required.</small>       
                </div>
                <div class="field col">
                    <label for="fw">Früherer Wert</label>
                    <InputText id="fw" v-model.trim="konds.fw" required="true" autofocus :class="{'p-invalid': submitted && !konds.fw}" />
                    <small class="p-error" v-if="submitted && !konds.fw">Name is required.</small>          
                </div>
                <div class="field col">
                    <label for="nw">Neuer Wert</label>
                    <InputText id="nw" v-model.trim="konds.nw" required="true" autofocus :class="{'p-invalid': submitted && !konds.nw}" />
                    <small class="p-error" v-if="submitted && !konds.nw">Name is required.</small>          
                </div>
            </div>


             <div class="formgrid grid">
                <div class="field col">
                    <label for="gv">Gültig von</label>
                    <InputText id="gv" v-model.trim="konds.gv" required="true" autofocus :class="{'p-invalid': submitted && !konds.gv}" />
                    <small class="p-error" v-if="submitted && !konds.gv">Name is required.</small>       
                </div>
                <div class="field col">
                    <label for="gb">Gültig bis</label>
                    <InputText id="gb" v-model.trim="konds.gb" required="true" autofocus :class="{'p-invalid': submitted && !konds.gb}" />
                    <small class="p-error" v-if="submitted && !konds.gb">Name is required.</small>          
                </div>
            </div>

            <div class="field">
				<label for="inventoryStatus" class="mb-3">Status</label>
				<Dropdown id="inventoryStatus" v-model="product.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
					<template #value="slotProps">
						<div v-if="slotProps.value && slotProps.value.value">
							<span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
						</div>
						<div v-else-if="slotProps.value && !slotProps.value.value">
							<span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
						</div>
						<span v-else>
							{{slotProps.placeholder}}
						</span>
					</template>
				</Dropdown>
			</div>


            <div class="formgrid grid">
                <div class="field col">
                    <label for="edate">Erstellungsdatum</label>
                    <InputText id="edate" v-model.trim="konds.edate" required="true" autofocus :class="{'p-invalid': submitted && !konds.edate}" />
                    <small class="p-error" v-if="submitted && !konds.edate">Name is required.</small>       
                </div>
                <div class="field col">
                    <label for="ename">Erstellername</label>
                    <InputText id="ename" v-model.trim="konds.ename" required="true" autofocus :class="{'p-invalid': submitted && !konds.ename}" />
                    <small class="p-error" v-if="submitted && !konds.ename">Name is required.</small>          
                </div>
            </div>
            
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
        </Dialog>
	</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import ProductService from '../service/ProductService';

import CustomerListService from '../service/CustomerListService';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
// import {FilterMatchMode} from 'primevue/api';

import axios from 'axios';

 
export default {

    name: 'PDFRenderKonditionen',

    data() {
  
        return {
            
            selectedItem: null,
            item: [
                {name: 'Zahl 12,34', id:0 },
                {name: '%-Wert', id:1 },
                {name: 'Text', id:2 }
            ],
            
            products: null,
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            product: {},
            selectedProducts: null,
            submitted: false,
            statuses: [
				{label: 'hinterlegt', value: 'hinterlegt'},
				{label: 'freigegeben', value: 'freigegeben'},
				{label: 'gesendet', value: 'gesendet'}
            ],
            nodes: null,
            value:null,
            expandedRows: [] ,

            editingRows: [],
            columns: null,

            selectedColumns: null,
            
            filters: {
                'global': {value: '', matchMode: FilterMatchMode.CONTAINS},
                'kuid': {value: 'kuid', matchMode: FilterMatchMode.CONTAINS},
                'koid': {value: 'koid', matchMode: FilterMatchMode.CONTAINS},
                'kotyp': {value: 'kotyp', matchMode: FilterMatchMode.CONTAINS},
                'kober': {value: 'kober', matchMode: FilterMatchMode.CONTAINS},
                'koname': { value: 'koname', matchMode: FilterMatchMode.CONTAINS},
                'wtyp': { value: 'wtyp', matchMode: FilterMatchMode.CONTAINS},
                'fw': { value: 'fw', matchMode: FilterMatchMode.CONTAINS},
                'nw': { value: 'nw', matchMode: FilterMatchMode.CONTAINS},
                'gv': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'gb': { value: 'gb', matchMode: FilterMatchMode.CONTAINS},
                'zn': { value: 'zn', matchMode: FilterMatchMode.CONTAINS},
                'stat': { value: 'stat', matchMode: FilterMatchMode.CONTAINS},
                'edate': { value: 'edate', matchMode: FilterMatchMode.CONTAINS},
                'ename': { value: 'ename', matchMode: FilterMatchMode.CONTAINS},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
            },



            kond:null,
            konds:[],
            kunde: null,
            kuid:null,
            koid:null,
            kotyp:null,
            kober:null,
            koname:null,
            wtyps:[],
            
            wtyp:[],
            fw:null,
            nw:null,
            gv:null,
            gb:null, 
            zn:null,
            stat:null,
            edate:null,
            ename:null,
            
            kotype:null,
            kotypid:null,
            kotypname:null,
            kotypwtyp:null,
            kotypkober:null,
            kotypkoberid:null,
            kotypkobername:null,
            kotypkoberabc:null,

            loading1: true,
            loading2: true,

        }
    },
    productService: null,
    customerListService: null,
    created() {
        this.productService = new ProductService();
        this.customerListService = new CustomerListService();
        this.selectedColumns = this.columns;
        this.initFilters();

    },
    mounted() {
        this.getallEvents();
        this.productService.getProducts().then(data => this.products = data);
    },
    methods: {

        exportToPDF() {
            html2pdf(document.getElementById("pdf-export"), {
                margin: 0.2,
                filename: "Konditionen.pdf",
                jsPDF:{ unit: 'cm', format: 'A4', orientation: 'landscape' }
			});
        },

        async getallEvents(){

            try {
                const res = await axios.get(`./dummydata-k.json`);

                const konds = res.data.results;
                for (const kond of konds) {
                let kunde = kond.kunde
                let adr = kond.adr
                let kuid = kond.kuid
                let koid = kond.koid 
                let kotyp = kond.kotyp   
                    let kotypid = kond.kotypid
                    let kotypname = kond.kotypname
                    let kotypwtyp = kond.kotypwtyp
                    let kotypkober = kond.kotypkober
                    let kotypkoberid = kond.kotypkoberid
                    let kotypkobername = kond.kotypkobername
                    let kotypkoberabc = kond.kotypkoberabc


                let kober = kond.kober
                let koname = kond.koname
                let wtyp = kond.wtyp
                let fw = kond.fw
                let nw = kond.nw
                let gv = kond.gv
                let gb = kond.gb
                let zn = kond.zn
                let stat = kond.stat
                let edate = kond.edate
                let ename = kond.ename



                this.konds.push({ 
                    adr,
                    kunde, 
                    kuid,
                    koid,
                    kotyp,kotypid,kotypname,kotypwtyp,kotypkober,kotypkoberid,kotypkobername,kotypkoberabc,
                    kober,
                    koname,
                    wtyp,
                    fw,
                    nw,
                    gv,
                    gb,
                    zn,
                    stat,
                    edate,
                    ename
                    })
                
                }
                 console.log(konds);
        
            } catch (e) {
                console.error(e);
            }
           

        },
        

        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;

                default:
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
                break;
            }
        },

        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch(status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'lowstock':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },


        openNew() {
            this.product = {};
            this.submitted = false;
            this.productDialog = true;
        },
        hideDialog() {
            this.productDialog = false;
            this.submitted = false;
        },
        saveProduct() {
            this.submitted = true;

			if (this.product.name.trim()) {
                if (this.product.id) {
                    this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus.value: this.product.inventoryStatus;
                    this.products[this.findIndexById(this.product.id)] = this.product;
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
                }
                else {
                    this.product.id = this.createId();
                    this.product.code = this.createId();
                    this.product.image = 'product-placeholder.svg';
                    this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value : 'INSTOCK';
                    this.products.push(this.product);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
                }

                this.productDialog = false;
                this.product = {};
            }
        },
        editProduct(product) {
            this.product = {...product};
            this.productDialog = true;
        },
        confirmDeleteProduct(product) {
            this.product = product;
            this.deleteProductDialog = true;
        },
        deleteProduct() {
            this.products = this.products.filter(val => val.id !== this.product.id);
            this.deleteProductDialog = false;
            this.product = {};
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;
        },
        createId() {
            let id = '';
            var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for ( var i = 0; i < 5; i++ ) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        },
        // initFilters() {
        //     this.filters = {
        //         'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        //     }
        // },


        formatDate(value) {
            return value.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('le-de', {style: 'currency', currency: 'EUR'});
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kuid': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'koid': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kotyp': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'kober': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'koname': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'wtyp': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'fw': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'nw': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'gv': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'gb': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'zn': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'stat': { value: null, matchMode: FilterMatchMode.CONTAINS},
                'edate': { value: null, matchMode: FilterMatchMode.CONTAINS},


            }
        },

        onRowExpand(event) {
            this.$toast.add({severity: 'info', summary: 'Kundenakte geöffnet', detail: event.data.name, life: 3000});
        },
        onRowCollapse(event) {
            this.$toast.add({severity: 'success', summary: 'Kundenakte geschlossen', detail: event.data.name, life: 3000});
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
            this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        },
        collapseAll() {
            this.expandedRows = null;
            this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },

        onColReorder() {
            this.$toast.add({severity:'success', summary: 'Column Reordered', life: 3000});
        },
        onRowReorder(event) {
            this.konds = event.value;
            this.$toast.add({severity:'success', summary: 'Rows Reordered', life: 3000});
        }
    },
    computed: {

    }
}
</script>
<style lang="scss" scoped>
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 50px;
    margin: 0 auto 2rem auto;
    display: block;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
        
		.p-button {
            margin-bottom: 0.25rem;
        }
	}
}
</style>