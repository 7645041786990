<template>

    <div class="flex flex-column h-full">
        <div class="flex align-items-center px-7 pt-1" style="height:60px">
           <a href="#AppStart"><img src="images/fritz-logo.webp"  alt="Fritz Logo" style="width:115px;height:auto;"></a>
        </div>
        
        <div class="col-12 layout-sidebar mt-7 overflow-y-auto">
           
            <ul class="list-none p-1 pl-2 m-0 mainnav">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple orange router-link-active" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                         <a href="#/Konditionen">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Konditionen</span>
                                <i class="pi pi-chevron-up absolute right-0 mr-2"></i>
                            </a>  
                        </div>

                        <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 pl-45 text-gray-800  border-1 border-transparent hover:bg-primary-200 transition-duration-150 transition-colors p-ripple" style="border-radius: 30px" href="#/Konditionsblatt">
                                <i class="pi mr-3"></i>
                                <span class="font-medium">Konditionsblatt</span>
                            </a>
                        </li>
                        </ul>
                </li>
                <hr class="orange"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0 mainnav">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple blue" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="#/Accounts">
                            <i class="pi pi-users mr-3 text-lg text-primary"></i>
                            <span class="font-medium text-base">Accounts</span>
                        </a>
                    </div>
                </li>
                <hr class="blu"/> 
            </ul>
            
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple green" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="#">
                            <i class="pi pi-folder mr-3 text-lg text-green-400"></i>
                            <span class="font-medium text-base">Dokumente</span>
                        </a>
                        
                    </div>
                   
                </li>
                <hr class="green"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple yellow" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="#">
                            <i class="pi pi-clock mr-3 text-lg text-yellow-400"></i>
                            <span class="font-medium text-base">Zeitplan</span>
                        </a>    
                    </div>
                    
                </li>
                <hr class="yellow"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple teal" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-sync mr-3 text-lg text-teal-200"></i>
                            <span class="font-medium text-base">Operative</span>
                        </a>
                    </div>
                   
                </li>
                <hr class="cyan"/> 
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple cyan" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="#">
                            <i class="pi pi-chart-line mr-3 text-lg text-cyan-700"></i>
                            <span class="font-medium text-base">Forecast</span>
                        </a>
                    </div>
                </li>
                <hr class="cyan"/>  
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-90 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple pink" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-90" href="https://wikipedia.de">
                            <i class="pi pi-chart-pie mr-3 text-lg text-pink-500"></i>
                            <span class="font-medium text-base">Reporting</span>
                        </a>
                    </div>
                </li>
                <hr class="pink"/>
            </ul>
            <ul class="list-none p-1 pl-2 m-0">
                <li>
                    <div v-ripple class="flex align-items-center justify-content-start text-black-900 cursor-pointer p-3 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors p-ripple purple" style="border-radius: 30px" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                        <a class="text-black-900" href="#">
                            <i class="pi pi-send mr-3 text-lg text-purple-600"></i>
                            <span class="font-medium text-base">Marketing</span>
                        </a>
                    </div>
                </li>
                <hr class="purple"/> 
            </ul>
        
        </div>
       
    </div>


</template>

<script>
</script>
